<template>
    
    <page page="arrangementen" theme="light" type="page">

        <transition name="bounce-up" mode="out-in">

            <router-view></router-view>

        </transition>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
export default {
    data() {
       return {}
    },
    methods: {

    },
    components: {
        Page
    }
}
</script>
